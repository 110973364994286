import React from "react"
import { Tooltip } from 'reactstrap';
import { type } from "os";
import styles from "./styles.module.css";

class _Tooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
             tooltipOpen: false
        };
    }

     toggle = () => {
        this.setState({
          tooltipOpen: !this.state.tooltipOpen
        });
      }
   

    render() {
        const {placement='top',header,text,target,tooltipClassName=null} = this.props;
        return (
            <>
                <Tooltip placement={placement} isOpen={this.state.tooltipOpen} target={target} toggle={this.toggle} autohide={false} className={tooltipClassName}>
                    {header && <h3>{header}</h3> }
                    {Array.isArray(text) ? text.map((x,idx) => <div key={idx} className={[idx != text.length && styles.nextLine, x=="" && styles.blankLine].join(" ")}>{x}</div>) : text}
                    {/* {
                        text2 &&
                        <>
                            <br/><br/>
                            {text2}
                        </>
                    } */}
                </Tooltip>
            </>

        )
    }
}


export default _Tooltip

