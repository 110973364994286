//API
// export const BASEURL = "http://localhost:59092/";
export const BASEURL = process.env.GATSBY_API_URL;
export const API = BASEURL + "api/";
export const CLOUD_LOGIN_URL = process.env.GATSBY_CLOUD_LOGIN_URL;

// Account
export const ACCOUNT = API + "account/";
export const WEBSITE = API; //admin

export const TOKEN =  BASEURL + "token"; // token
export const CUSTOMERDETAIL =  ACCOUNT + "customer/viewDetails"; // customers Detail

export const WEBSITEORDERS = WEBSITE + "orders/";
export const WEBSITECUSTOMERS = WEBSITE + "customers/";

export const PRICEINFO = WEBSITEORDERS + "priceInfo";
export const PRODUCTPRICING = WEBSITEORDERS + "productPricing";
export const CREATEPRELIMINARY = WEBSITEORDERS + "createPreliminary";
export const CHECKEMAIL = WEBSITEORDERS + "checkEmail";
export const REQUESTPASSWORDRESET = WEBSITECUSTOMERS + "requestResetPassword";
export const VALIDATEPASSWORDRESETCODE = WEBSITECUSTOMERS + "validatePasswordResetCode";
export const FORGOTCUSTOMERID = WEBSITECUSTOMERS + "forgotCustomerId";
export const SETNEWPASSWORD = WEBSITECUSTOMERS + "setNewPassword";

export const ACCOUNTORDERS = ACCOUNT + "orders/";
export const ACCOUNTPRICEINFO = ACCOUNTORDERS + "priceInfo";
export const ACCOUNTCREATEPRELIMINARY = ACCOUNTORDERS + "createPreliminary";
export const UPGRADEPRICEINFO = ACCOUNTORDERS + "upgradePriceInfo";
export const CREATEPENDING = ACCOUNTORDERS + "createPending";
export const PROCESSPAYUMONEY = ACCOUNTORDERS + "processPayUMoney";
export const PROCESSRAZORPAY = ACCOUNTORDERS + "processRazorPay";
export const EDITPENDING = ACCOUNTORDERS + "editPending";

export const ORDERLIST = ACCOUNTORDERS + "viewList";

export const CUSTOMER = ACCOUNT + "customer/"
export const CUSTOMERORDERPLACEMENT = CUSTOMER + "viewOrderPlacementDetails";
export const CUSTOMERPLAN = CUSTOMER + "viewPlan";
export const CUSTOMERDETAILS = CUSTOMER + "viewDetails";
export const CUSTOMERPROFILEDETAILS = CUSTOMER + "viewProfileDetails";
export const CUSTOMERREFERRALDETAILS = CUSTOMER + "viewReferralDetails";
export const EDITCUSTOMERDETAILS = CUSTOMER + "editDetails";
export const EDITCORRESPONDENCEEMAILS = CUSTOMER + "editCorrespondence";
export const SHAREREFERRALCODE = CUSTOMER + "shareReferralCode";
export const VIEWGSTINVOICE = CUSTOMER + "viewInvoice";

export const ADDDOWNLOAD = WEBSITE + "downloads/add";

export const CLOUDADDON = CUSTOMER + "cloudAddOn/";
export const VIEWCLOUDADDONS = CLOUDADDON + "view";
export const CREATECLOUDADDONS = CLOUDADDON + "create";
export const DELETECLOUDADDONS = CLOUDADDON + "delete";

export const CLOUDSIGNUP = API + "cloud/signup";
export const CLOUDINQUIRY = API + "cloud/inquiry";
export const AFFILIATEDISCOUNT = API + "affiliate/discount";
export const REFERRALDISCOUNT = API + "referral/discount";

export const SUBSCRIPTIONPREFS = API + "subscription-prefs";

export const PLANRECOMMENDATION = ACCOUNT + "planRecommendation/";
export const PLANRECOMMENDATIONDETAILS = PLANRECOMMENDATION + "details";
export const PLANRECOMMENDATIONREFRESH = PLANRECOMMENDATION + "refresh";
export const PLANRECOMMENDATIONREQUESTPROFORMA = PLANRECOMMENDATION + "requestProforma";
export const PLANRECOMMENDATIONUPDATEISWIDGETOPENED = PLANRECOMMENDATION + "updateIsWidgetOpened";
export const PLANRECOMMENDATIONGETISWIDGETOPENED = PLANRECOMMENDATION + "getIsWidgetOpened";