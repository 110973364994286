import { processResponse } from "../common/gFunctions";
import { TOKEN } from "./API";

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
  var user = isBrowser() && window.localStorage.getItem("mprofitUserAccount")
    ? JSON.parse(window.localStorage.getItem("mprofitUserAccount"))
    : null;
  user = user && user.access_token ? user : null;
  if (user) {
    var expiresDt = new Date(user['.expires']);
    if (expiresDt <= new Date()) {
        user = null
    }
  }
  return user;
}

const setUser = (user) => {
  console.log('setuser',user)
  window.localStorage.setItem("mprofitUserAccount", JSON.stringify(user))
}

export const handleLogin = ({ email, password, useCustomerId, mfaOTP, affiliateUTM }) => {

  var loginDetails = {
    'grant_type':'password',
    'username': email,
    'password': password,
    'UseCustomerId': useCustomerId ? 1 : 0,
    'UserType': 0,
  };

  if (mfaOTP != null) {
    loginDetails.MFAOTP = mfaOTP
  }

  if (affiliateUTM != null) {
    loginDetails.AffiliateUTM = affiliateUTM;
  }

  console.log('loginDetails',loginDetails, affiliateUTM);

  var loginBody = [];
  for (var property in loginDetails) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(loginDetails[property]);
      loginBody.push(encodedKey + "=" + encodedValue);
  }
  loginBody = loginBody.join("&");

  return fetch(TOKEN, {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: loginBody
  }).then(processResponse)
  .then(({error,response}) => {
    console.log(response,'userresponse')
    if (error.status<400) {
      const access_rights = response.roles.split(',');
      setUser({email,access_rights,...response})
    }

    return {error,response};
  })
}

export const isLoggedIn = () => {
  const user = getUser()
  return user!=null && user.access_token!=null
}

export const getToken = () => {
  const user = getUser();
  return user && user.access_token;
}

export const logout = callback => {
  setUser({})
  if (callback) {
    callback()
  }
}

export const isUserAdmin = (usersAccessRights) => {
  return usersAccessRights.includes('ClientAdmin');
}