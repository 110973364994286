export const Order_Status = {
    NULL: 0,
    PENDING: 1,
    PAID_AND_PROCESSED: 2,
    DECLINED: 3,
    CANCELLED: 4
}

export const System_Type = {
    DESKTOP: 0,
    CLOUD: 1
}

export const Product_Type = {
    INVESTOR: 0,
    PRO: 1,
    ADVISOR: 2,
    UNDEFINED: -999
}

export const Payment_Mode = {
    NONE: -999,
    ONLINE: 0,
    CHEQUE: 1,
    NEFT: 2,
    PAYUMONEY_LINK: 3,
    PAYTM: 4,
    FLIPKART: 5,
    SNAPDEAL: 6,
    AMAZON: 7,
    PAYTM_WALLET: 8,
    CC_AVENUE_LINK: 9,
    CASH: 10,
    RAZORPAY_LINK: 11,
    RAZORPAY_ONLINE: 12,
    OTHER: 99
}

export const LinkType = {
    NONE: 0,
    IMAGE: 1,
    DOWNLOAD: 2
}

export const True_False = {
    TRUE: 1,
    FALSE: -1,
    SELECT: -999
}

export const Discount_Type = {
    NONE: 0,
    DAYS_ONLY: 1,
    CASH_ONLY: 2,
    PERCENT_ONLY: 3,
    CASH_DAYS: 4,
    PERCENT_DAYS: 5
}

export const Order_Type = {
    NEW: 0,
    RENEWAL: 1,
    REGULAR_UPGRADE: 2,
    EXTEND_UPGRADE: 3
}

export const Additional_Services = {
    XIRR_INVESTORREPORTS: 1,
    PMS_PACK: 3,
    API_PACK: 5,
    HOLDING_API: 6
}

export const Additional_Services_Name = {
    [Additional_Services.XIRR_INVESTORREPORTS]: "MProfit Premium Pack",
    [Additional_Services.PMS_PACK]: "MProfit PMS Pack"
}

export const AdditionalServicesOrderTooltip_Pro = [
    "Add the MProfit Pro Premium Pack to provide your clients with direct access in their MProfit logins to the following:",
    "- Annualised Returns (XIRR) in app & reports",
    "- Capital Gain reports in Income Tax format",
    "- Historical Valuation reports",
    "- Transaction reports",
    "- Unrealised Capital Gain reports",
    "- Holding Period, Stock Register & Closing Balance reports",
    "- Due Date reports",
    "",
    "Please note that if you would like to add your logo for branding, you will have to subscribe to MProfit Advisor 100 or above, and opt for the Advisor Premium Pack instead (which includes all reports given above + logo branding)."
]

export const AdditionalServicesOrderTooltip_Advisor = [
    "Add the MProfit Advisor Premium Pack to add your logo for branding to your MProfit client logins on web & mobile, and in client reports.",
    "",
    "By opting for this pack, your clients also get direct access in their MProfit logins to the following:",
    "- Annualised Returns (XIRR) in app & reports",
    "- Capital Gain reports in Income Tax format",
    "- Historical Valuation reports",
    "- Transaction reports",
    "- Unrealised Capital Gain reports",
    "- Holding Period, Stock Register & Closing Balance reports",
    "- Due Date reports"
]

export const CloudB2C_ProductIDs = {
    FREE: 600,
    LITE: 601,
    PLUS: 602,
    HNI: 603,
    FAMILYOFFICE: 604
}

export const DesktopB2C_ProductIDs = {
    DESKTOP_LITE: 201,
    DESKTOP_PLUS: 202,
    DESKTOP_SINGLEFAMILY: 203,
    DESKTOP_MULTIFAMILY: 204
}

export const CloudB2B2C_ProductIDs = {
    ESSENTIAL: {
        P100: 801,
        P200: 802,
        P500: 803,
        P1000: 804,
        P2000: 805
    },
    ADVANCED: {
        P100: 901,
        P200: 902,
        P500: 903,
        P1000: 904,
        P2000: 905
    },
    PREMIUM: {
        P100: 1001,
        P200: 1002,
        P500: 1003,
        P1000: 1004,
        P2000: 1005
    },
    STANDARD: {
        P100: 1101,
        P200: 1102,
        P500: 1103,
        P1000: 1104,
        P2000: 1105
    },
    PLATINUM: {
        P2000: 1205
    }
}

export const DesktopB2B2C_ProductIDs = {
    PLATINUM: {
        P2000: 255
    }
}

export const CloudB2B2C_ProductIDsToNumPortfolios = {
    [CloudB2B2C_ProductIDs.ESSENTIAL.P100]: 100,
    [CloudB2B2C_ProductIDs.ESSENTIAL.P200]: 200,
    [CloudB2B2C_ProductIDs.ESSENTIAL.P500]: 500,
    [CloudB2B2C_ProductIDs.ESSENTIAL.P1000]: 1000,
    [CloudB2B2C_ProductIDs.ESSENTIAL.P2000]: 2000,
    [CloudB2B2C_ProductIDs.ADVANCED.P100]: 100,
    [CloudB2B2C_ProductIDs.ADVANCED.P200]: 200,
    [CloudB2B2C_ProductIDs.ADVANCED.P500]: 500,
    [CloudB2B2C_ProductIDs.ADVANCED.P1000]: 1000,
    [CloudB2B2C_ProductIDs.ADVANCED.P2000]: 2000,
    [CloudB2B2C_ProductIDs.PREMIUM.P100]: 100,
    [CloudB2B2C_ProductIDs.PREMIUM.P200]: 200,
    [CloudB2B2C_ProductIDs.PREMIUM.P500]: 500,
    [CloudB2B2C_ProductIDs.PREMIUM.P1000]: 1000,
    [CloudB2B2C_ProductIDs.PREMIUM.P2000]: 2000,
    [CloudB2B2C_ProductIDs.STANDARD.P100]: 100,
    [CloudB2B2C_ProductIDs.STANDARD.P200]: 200,
    [CloudB2B2C_ProductIDs.STANDARD.P500]: 500,
    [CloudB2B2C_ProductIDs.STANDARD.P1000]: 1000,
    [CloudB2B2C_ProductIDs.STANDARD.P2000]: 2000,
    [CloudB2B2C_ProductIDs.PLATINUM.P2000]: 2000
}

export const DesktopB2B2C_ProductIDsToNumPortfolios = {
    [DesktopB2B2C_ProductIDs.PLATINUM.P2000]: 2000
}

export const B2X = {
    Undefined: 0,
    B2B2C: 1,
    B2C: 2
}

export const B2C_Tier = {
    UNDEFINED: 0,
    FREE: 1,
    LITE: 2,
    PLUS: 3,
    HNI: 4,
    FAMILYOFFICE: 5,
    DESKTOP_LITE: 102,
    DESKTOP_PLUS: 103,
    DESKTOP_HNI: 104,
    DESKTOP_MULTIFAMILY: 105
}

export const B2B2C_Tier = {
    UNDEFINED: 0,
    ESSENTIAL: 1,
    ADVANCED: 2,
    PREMIUM: 3,
    STANDARD: 4,
    PLATINUM: 5
}

export const RecaptchaPublicKey = '6Lf-654UAAAAAFBeUNWxDq4xYA2DQO-oUhuXCqpY';

export const Customer_Inquiry_Type =
{
    UNCATEGORISED: 0,
    DEMO: 1,
    PRICING: 2
}

export const Client_Type =
{
    UNCATEGORISED: 0,
    B2C_OTHER: 10,
    B2C_NEW_INVESTOR: 11,
    B2C_EXPERIENCED_INVESTOR: 12,
    B2C_HNI: 13,
    B2C_FAMILYOFFICE: 14,
    B2B_OTHER: 30,
    B2B_MFD: 31,
    B2B_RIA: 32,
    B2B_CA: 33,
    B2B_SUBBROKER: 34,
    NONE: -999
}

export const CustomAUMLimit_Options =
{
    CONTACT_MPROFIT: -100,
    CR_25: 1,
    CR_50: 2,
    CR_100: 3,
    CR_200: 4,
    CR_500: 5,
    CR_2000: 6,
    CR_5000: 7,
    UNLIMITED: -2
}