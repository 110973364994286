import { B2B2C_Tier, B2C_Tier, B2X, Client_Type, CloudB2B2C_ProductIDs, CloudB2C_ProductIDs, CustomAUMLimit_Options, DesktopB2B2C_ProductIDs, DesktopB2C_ProductIDs, System_Type } from "./constants";

export const StateList = [
    { id: -999, text: "Select State", disabled: true },
    { id: 35, text: "Andaman And Nicobar Islands" },
    // { id: 28, text: "Andhra Pradesh (Before Division)" },
    { id: 37, text: "Andhra Pradesh" },
    { id: 12, text: "Arunachal Pradesh" },
    { id: 18, text: "Assam" },
    { id: 10, text: "Bihar" },
    { id: 4, text: "Chandigarh" },
    { id: 22, text: "Chhattisgarh" },
    { id: 26, text: "Dadra And Nagar Haveli" },
    { id: 25, text: "Daman And Diu" },
    { id: 7, text: "Delhi" },
    { id: 30, text: "Goa" },
    { id: 24, text: "Gujarat" },
    { id: 6, text: "Haryana" },
    { id: 2, text: "Himachal Pradesh" },
    { id: 1, text: "Jammu and Kashmir" },
    { id: 20, text: "Jharkhand" },
    { id: 29, text: "Karnataka" },
    { id: 32, text: "Kerala" },
    { id: 31, text: "Lakshwadeep" },
    { id: 23, text: "Madhya Pradesh" },
    { id: 27, text: "Maharashtra" },
    { id: 14, text: "Manipur" },
    { id: 17, text: "Meghalaya" },
    { id: 15, text: "Mizoram" },
    { id: 13, text: "Nagaland" },
    { id: 21, text: "Odisha" },
    { id: 34, text: "Puducherry" },
    { id: 3, text: "Punjab" },
    { id: 8, text: "Rajasthan" },
    { id: 11, text: "Sikkim" },
    { id: 33, text: "Tamil Nadu" },
    { id: 36, text: "Telangana" },
    { id: 16, text: "Tripura" },
    { id: 9, text: "Uttar Pradesh" },
    { id: 5, text: "Uttarakhand" },
    { id: 19, text: "West Bengal" },
    { id: -1, text: "Other" }
];

export const CountryList = [
  { id: -999, text: "Select Country", disabled: true },
  { id: "Afghanistan", text: "Afghanistan" },
  { id: "Åland Islands", text: "Åland Islands" },
  { id: "Albania", text: "Albania" },
  { id: "Algeria", text: "Algeria" },
  { id: "American Samoa", text: "American Samoa" },
  { id: "Andorra", text: "Andorra" },
  { id: "Angola", text: "Angola" },
  { id: "Anguilla", text: "Anguilla" },
  { id: "Antarctica", text: "Antarctica" },
  { id: "Antigua and Barbuda", text: "Antigua and Barbuda" },
  { id: "Argentina", text: "Argentina" },
  { id: "Armenia", text: "Armenia" },
  { id: "Aruba", text: "Aruba" },
  { id: "Australia", text: "Australia" },
  { id: "Austria", text: "Austria" },
  { id: "Azerbaijan", text: "Azerbaijan" },
  { id: "Bahrain", text: "Bahrain" },
  { id: "Bahamas", text: "Bahamas" },
  { id: "Bangladesh", text: "Bangladesh" },
  { id: "Barbados", text: "Barbados" },
  { id: "Belarus", text: "Belarus" },
  { id: "Belgium", text: "Belgium" },
  { id: "Belize", text: "Belize" },
  { id: "Benin", text: "Benin" },
  { id: "Bermuda", text: "Bermuda" },
  { id: "Bhutan", text: "Bhutan" },
  { id: "Bolivia Plurinational State of", text: "Bolivia Plurinational State of" },
  { id: "Bonaire Sint Eustatius and Saba", text: "Bonaire Sint Eustatius and Saba" },
  { id: "Bosnia and Herzegovina", text: "Bosnia and Herzegovina" },
  { id: "Botswana", text: "Botswana" },
  { id: "Bouvet Island", text: "Bouvet Island" },
  { id: "Brazil", text: "Brazil" },
  { id: "British Indian Ocean Territory", text: "British Indian Ocean Territory" },
  { id: "Brunei Darussalam", text: "Brunei Darussalam" },
  { id: "Bulgaria", text: "Bulgaria" },
  { id: "Burkina Faso", text: "Burkina Faso" },
  { id: "Burundi", text: "Burundi" },
  { id: "Cambodia", text: "Cambodia" },
  { id: "Cameroon", text: "Cameroon" },
  { id: "Canada", text: "Canada" },
  { id: "Cape Verde", text: "Cape Verde" },
  { id: "Cayman Islands", text: "Cayman Islands" },
  { id: "Central African Republic", text: "Central African Republic" },
  { id: "Chad", text: "Chad" },
  { id: "Chile", text: "Chile" },
  { id: "China", text: "China" },
  { id: "Christmas Island", text: "Christmas Island" },
  { id: "Cocos (Keeling) Islands", text: "Cocos (Keeling) Islands" },
  { id: "Colombia", text: "Colombia" },
  { id: "Comoros", text: "Comoros" },
  { id: "Congo", text: "Congo" },
  { id: "Congo the Democratic Republic of the", text: "Congo the Democratic Republic of the" },
  { id: "Cook Islands", text: "Cook Islands" },
  { id: "Costa Rica", text: "Costa Rica" },
  { id: "Côte d'Ivoire", text: "Côte d'Ivoire" },
  { id: "Croatia", text: "Croatia" },
  { id: "Cuba", text: "Cuba" },
  { id: "Curaçao", text: "Curaçao" },
  { id: "Cyprus", text: "Cyprus" },
  { id: "Czech Republic", text: "Czech Republic" },
  { id: "Denmark", text: "Denmark" },
  { id: "Djibouti", text: "Djibouti" },
  { id: "Dominica", text: "Dominica" },
  { id: "Dominican Republic", text: "Dominican Republic" },
  { id: "Ecuador", text: "Ecuador" },
  { id: "Egypt", text: "Egypt" },
  { id: "El Salvador", text: "El Salvador" },
  { id: "Equatorial Guinea", text: "Equatorial Guinea" },
  { id: "Eritrea", text: "Eritrea" },
  { id: "Estonia", text: "Estonia" },
  { id: "Ethiopia", text: "Ethiopia" },
  { id: "Falkland Islands (Malvinas)", text: "Falkland Islands (Malvinas)" },
  { id: "Faroe Islands", text: "Faroe Islands" },
  { id: "Fiji", text: "Fiji" },
  { id: "Finland", text: "Finland" },
  { id: "France", text: "France" },
  { id: "French Guiana", text: "French Guiana" },
  { id: "French Polynesia", text: "French Polynesia" },
  { id: "French Southern Territories", text: "French Southern Territories" },
  { id: "Gabon", text: "Gabon" },
  { id: "Gambia", text: "Gambia" },
  { id: "Georgia", text: "Georgia" },
  { id: "Germany", text: "Germany" },
  { id: "Ghana", text: "Ghana" },
  { id: "Gibraltar", text: "Gibraltar" },
  { id: "Greece", text: "Greece" },
  { id: "Greenland", text: "Greenland" },
  { id: "Grenada", text: "Grenada" },
  { id: "Guadeloupe", text: "Guadeloupe" },
  { id: "Guam", text: "Guam" },
  { id: "Guatemala", text: "Guatemala" },
  { id: "Guernsey", text: "Guernsey" },
  { id: "Guinea", text: "Guinea" },
  { id: "Guinea-Bissau", text: "Guinea-Bissau" },
  { id: "Guyana", text: "Guyana" },
  { id: "Haiti", text: "Haiti" },
  { id: "Heard Island and McDonald Islands", text: "Heard Island and McDonald Islands" },
  { id: "Holy See (Vatican City State)", text: "Holy See (Vatican City State)" },
  { id: "Honduras", text: "Honduras" },
  { id: "Hong Kong", text: "Hong Kong" },
  { id: "Hungary", text: "Hungary" },
  { id: "Iceland", text: "Iceland" },
  { id: "India", text: "India" },
  { id: "Indonesia", text: "Indonesia" },
  { id: "Iran Islamic Republic of", text: "Iran Islamic Republic of" },
  { id: "Iraq", text: "Iraq" },
  { id: "Ireland", text: "Ireland" },
  { id: "Isle of Man", text: "Isle of Man" },
  { id: "Israel", text: "Israel" },
  { id: "Italy", text: "Italy" },
  { id: "Jamaica", text: "Jamaica" },
  { id: "Japan", text: "Japan" },
  { id: "Jersey", text: "Jersey" },
  { id: "Jordan", text: "Jordan" },
  { id: "Kazakhstan", text: "Kazakhstan" },
  { id: "Kenya", text: "Kenya" },
  { id: "Kiribati", text: "Kiribati" },
  { id: "Korea Democratic People's Republic of", text: "Korea Democratic People's Republic of" },
  { id: "Korea Republic of", text: "Korea Republic of" },
  { id: "Kuwait", text: "Kuwait" },
  { id: "Kyrgyzstan", text: "Kyrgyzstan" },
  { id: "Lao People's Democratic Republic", text: "Lao People's Democratic Republic" },
  { id: "Latvia", text: "Latvia" },
  { id: "Lebanon", text: "Lebanon" },
  { id: "Lesotho", text: "Lesotho" },
  { id: "Liberia", text: "Liberia" },
  { id: "Libya", text: "Libya" },
  { id: "Liechtenstein", text: "Liechtenstein" },
  { id: "Lithuania", text: "Lithuania" },
  { id: "Luxembourg", text: "Luxembourg" },
  { id: "Macao", text: "Macao" },
  { id: "Macedonia the Former Yugoslav Republic of", text: "Macedonia the Former Yugoslav Republic of" },
  { id: "Madagascar", text: "Madagascar" },
  { id: "Malawi", text: "Malawi" },
  { id: "Malaysia", text: "Malaysia" },
  { id: "Maldives", text: "Maldives" },
  { id: "Mali", text: "Mali" },
  { id: "Malta", text: "Malta" },
  { id: "Marshall Islands", text: "Marshall Islands" },
  { id: "Martinique", text: "Martinique" },
  { id: "Mauritania", text: "Mauritania" },
  { id: "Mauritius", text: "Mauritius" },
  { id: "Mayotte", text: "Mayotte" },
  { id: "Mexico", text: "Mexico" },
  { id: "Micronesia Federated States of", text: "Micronesia Federated States of" },
  { id: "Moldova Republic of", text: "Moldova Republic of" },
  { id: "Monaco", text: "Monaco" },
  { id: "Mongolia", text: "Mongolia" },
  { id: "Montenegro", text: "Montenegro" },
  { id: "Montserrat", text: "Montserrat" },
  { id: "Morocco", text: "Morocco" },
  { id: "Mozambique", text: "Mozambique" },
  { id: "Myanmar", text: "Myanmar" },
  { id: "Namibia", text: "Namibia" },
  { id: "Nauru", text: "Nauru" },
  { id: "Nepal", text: "Nepal" },
  { id: "Netherlands", text: "Netherlands" },
  { id: "New Caledonia", text: "New Caledonia" },
  { id: "New Zealand", text: "New Zealand" },
  { id: "Nicaragua", text: "Nicaragua" },
  { id: "Niger", text: "Niger" },
  { id: "Nigeria", text: "Nigeria" },
  { id: "Niue", text: "Niue" },
  { id: "Norfolk Island", text: "Norfolk Island" },
  { id: "Northern Mariana Islands", text: "Northern Mariana Islands" },
  { id: "Norway", text: "Norway" },
  { id: "Oman", text: "Oman" },
  { id: "Pakistan", text: "Pakistan" },
  { id: "Palau", text: "Palau" },
  { id: "Palestine State of", text: "Palestine State of" },
  { id: "Panama", text: "Panama" },
  { id: "Papua New Guinea", text: "Papua New Guinea" },
  { id: "Paraguay", text: "Paraguay" },
  { id: "Peru", text: "Peru" },
  { id: "Philippines", text: "Philippines" },
  { id: "Pitcairn", text: "Pitcairn" },
  { id: "Poland", text: "Poland" },
  { id: "Portugal", text: "Portugal" },
  { id: "Puerto Rico", text: "Puerto Rico" },
  { id: "Qatar", text: "Qatar" },
  { id: "Réunion", text: "Réunion" },
  { id: "Romania", text: "Romania" },
  { id: "Russian Federation", text: "Russian Federation" },
  { id: "Rwanda", text: "Rwanda" },
  { id: "Saint Barthélemy", text: "Saint Barthélemy" },
  { id: "Saint Helena Ascension and Tristan da Cunha", text: "Saint Helena Ascension and Tristan da Cunha" },
  { id: "Saint Kitts and Nevis", text: "Saint Kitts and Nevis" },
  { id: "Saint Lucia", text: "Saint Lucia" },
  { id: "Saint Martin (French part)", text: "Saint Martin (French part)" },
  { id: "Saint Pierre and Miquelon", text: "Saint Pierre and Miquelon" },
  { id: "Saint Vincent and the Grenadines", text: "Saint Vincent and the Grenadines" },
  { id: "Samoa", text: "Samoa" },
  { id: "San Marino", text: "San Marino" },
  { id: "Sao Tome and Principe", text: "Sao Tome and Principe" },
  { id: "Saudi Arabia", text: "Saudi Arabia" },
  { id: "Senegal", text: "Senegal" },
  { id: "Serbia", text: "Serbia" },
  { id: "Seychelles", text: "Seychelles" },
  { id: "Sierra Leone", text: "Sierra Leone" },
  { id: "Singapore", text: "Singapore" },
  { id: "Sint Maarten (Dutch part)", text: "Sint Maarten (Dutch part)" },
  { id: "Slovakia", text: "Slovakia" },
  { id: "Slovenia", text: "Slovenia" },
  { id: "Solomon Islands", text: "Solomon Islands" },
  { id: "Somalia", text: "Somalia" },
  { id: "South Africa", text: "South Africa" },
  { id: "South Georgia and the South Sandwich Islands", text: "South Georgia and the South Sandwich Islands" },
  { id: "South Sudan", text: "South Sudan" },
  { id: "Spain", text: "Spain" },
  { id: "Sri Lanka", text: "Sri Lanka" },
  { id: "Sudan", text: "Sudan" },
  { id: "Suriname", text: "Suriname" },
  { id: "Svalbard and Jan Mayen", text: "Svalbard and Jan Mayen" },
  { id: "Swaziland", text: "Swaziland" },
  { id: "Sweden", text: "Sweden" },
  { id: "Switzerland", text: "Switzerland" },
  { id: "Syrian Arab Republic", text: "Syrian Arab Republic" },
  { id: "Taiwan Province of China", text: "Taiwan Province of China" },
  { id: "Tajikistan", text: "Tajikistan" },
  { id: "Tanzania United Republic of", text: "Tanzania United Republic of" },
  { id: "Thailand", text: "Thailand" },
  { id: "Timor-Leste", text: "Timor-Leste" },
  { id: "Togo", text: "Togo" },
  { id: "Tokelau", text: "Tokelau" },
  { id: "Tonga", text: "Tonga" },
  { id: "Trinidad and Tobago", text: "Trinidad and Tobago" },
  { id: "Tunisia", text: "Tunisia" },
  { id: "Turkey", text: "Turkey" },
  { id: "Turkmenistan", text: "Turkmenistan" },
  { id: "Turks and Caicos Islands", text: "Turks and Caicos Islands" },
  { id: "Tuvalu", text: "Tuvalu" },
  { id: "Uganda", text: "Uganda" },
  { id: "Ukraine", text: "Ukraine" },
  { id: "United Arab Emirates", text: "United Arab Emirates" },
  { id: "United Kingdom", text: "United Kingdom" },
  { id: "United States", text: "United States" },
  { id: "United States Minor Outlying Islands", text: "United States Minor Outlying Islands" },
  { id: "Uruguay", text: "Uruguay" },
  { id: "Uzbekistan", text: "Uzbekistan" },
  { id: "Vanuatu", text: "Vanuatu" },
  { id: "Venezuela Bolivarian Republic of", text: "Venezuela Bolivarian Republic of" },
  { id: "Viet Nam", text: "Viet Nam" },
  { id: "Virgin Islands British", text: "Virgin Islands British" },
  { id: "Virgin Islands U.S.", text: "Virgin Islands U.S." },
  { id: "Wallis and Futuna", text: "Wallis and Futuna" },
  { id: "Western Sahara", text: "Western Sahara" },
  { id: "Yemen", text: "Yemen" },
  { id: "Zambia", text: "Zambia" },
  { id: "Zimbabwe", text: "Zimbabwe" },
]

export const DesktopProductsPricingPage = [
    {id:-999, text: "Select product", disabled: true },
    {id: 101, text: "MProfit Investor+"},
    {id: 102, text: "MProfit Pro"},
    {id: 103, text: "MProfit Advisor 100"},
    {id: 104, text: "MProfit Advisor 200"},
    {id: 105, text: "MProfit Advisor 500"},
    {id: 106, text: "MProfit Advisor 1000"}
]

export const DesktopProducts = [
    {id:-999, text: "Select product", disabled: true },
    {id: 101, text: "MProfit Investor+", showAdditionalServices: false, hideFreeLogins: true},
    {id: 102, text: "MProfit Pro", showAdditionalServices: true, hideFreeLogins: true},
    {id: 103, text: "MProfit Advisor 100", showAdditionalServices: true},
    {id: 104, text: "MProfit Advisor 200", showAdditionalServices: true},
    {id: 105, text: "MProfit Advisor 500", showAdditionalServices: true},
    {id: 106, text: "MProfit Advisor 1000", showAdditionalServices: true},
    {id: 107, text: "MProfit Advisor 2000", showAdditionalServices: true},
    {id: 108, text: "MProfit Advisor 3000", showAdditionalServices: true},
    {id: 109, text: "MProfit Advisor 4000", showAdditionalServices: true},
    {id: 110, text: "MProfit Advisor 5000", showAdditionalServices: true}
]

export const DesktopProductsDownload = [
    {id:-999, text: "Select product", disabled: true },
    {id: 101, text: "MProfit Investor+"},
    {id: 102, text: "MProfit Pro"},
    {id: 103, text: "MProfit Advisor"}
]

export const CloudProductsPricingPage = [
    {id:-999, text: "Select product", disabled: true },
    {id: 301, text: "MProfit Investor+"},
    {id: 302, text: "MProfit Pro" },
    {id: 303, text: "MProfit Advisor 100" },
    {id: 304, text: "MProfit Advisor 200" },
    {id: 305, text: "MProfit Advisor 500" },
    {id: 306, text: "MProfit Advisor 1000" },
]

export const CloudB2CProductsPricingPage = [
    {id: -999, text: "Select product", disabled: true },
    {id: B2C_Tier.LITE, text: "MProfit Lite"},
    {id: B2C_Tier.PLUS, text: "MProfit Plus" },
    {id: B2C_Tier.HNI, text: "MProfit HNI" },
    {id: B2C_Tier.FAMILYOFFICE, text: "MProfit Family Office" }
]

export const CloudB2B2CProductsOldPricingPage = [
    {id: -999, text: "Select product", disabled: true },
    {id: B2B2C_Tier.ESSENTIAL, text: "MProfit Wealth Essential"},
    {id: B2B2C_Tier.ADVANCED, text: "MProfit Wealth Advanced" },
    {id: B2B2C_Tier.PREMIUM, text: "MProfit Wealth Premium" },
]

export const CloudB2B2CProductsPricingPage = [
    {id: -999, text: "Select product", disabled: true },
    {id: B2B2C_Tier.STANDARD, text: "MProfit Wealth Standard"},
    {id: B2B2C_Tier.PLATINUM, text: "MProfit Wealth Platinum" },
]

export const CloudB2B2CProductsPricingPageAll = [
    {id: -999, text: "Select product", disabled: true },
    {id: B2B2C_Tier.ESSENTIAL, text: "MProfit Wealth Essential"},
    {id: B2B2C_Tier.ADVANCED, text: "MProfit Wealth Advanced" },
    {id: B2B2C_Tier.PREMIUM, text: "MProfit Wealth Premium" },
    {id: B2B2C_Tier.STANDARD, text: "MProfit Wealth Standard"},
    {id: B2B2C_Tier.PLATINUM, text: "MProfit Wealth Platinum" },
]

export const CloudProducts = [
    {id:-999, text: "Select product", disabled: true },
    {id: 301, text: "MProfit Investor+", showAdditionalServices: true, hideFreeLogins: true},
    {id: 302, text: "MProfit Pro", showAdditionalServices: true, hideFreeLogins: true},
    {id: 303, text: "MProfit Advisor 100", showAdditionalServices: true},
    {id: 304, text: "MProfit Advisor 200", showAdditionalServices: true},
    {id: 305, text: "MProfit Advisor 500", showAdditionalServices: true},
    {id: 306, text: "MProfit Advisor 1000", showAdditionalServices: true},
    {id: 307, text: "MProfit Advisor 2000", showAdditionalServices: true},
    {id: 308, text: "MProfit Advisor 3000", showAdditionalServices: true},
    {id: 309, text: "MProfit Advisor 4000", showAdditionalServices: true},
    {id: 310, text: "MProfit Advisor 5000", showAdditionalServices: true}
]

export const CloudB2CPaidProducts = [
    {id:-999, text: "Select product", disabled: true },
    {id: CloudB2C_ProductIDs.LITE, text: "MProfit Lite"},
    {id: CloudB2C_ProductIDs.PLUS, text: "MProfit Plus"},
    {id: CloudB2C_ProductIDs.HNI, text: "MProfit HNI"},
    {id: CloudB2C_ProductIDs.FAMILYOFFICE, text: "MProfit Family Office"},
]

export const DesktopB2CPaidProducts = [
    {id:-999, text: "Select product", disabled: true },
    {id: DesktopB2C_ProductIDs.DESKTOP_LITE, text: "MProfit Lite (Desktop)"},
    {id: DesktopB2C_ProductIDs.DESKTOP_PLUS, text: "MProfit Plus (Desktop)"},
    {id: DesktopB2C_ProductIDs.DESKTOP_SINGLEFAMILY, text: "MProfit HNI (Desktop)"},
    {id: DesktopB2C_ProductIDs.DESKTOP_MULTIFAMILY, text: "MProfit Family Office (Desktop)"},
]

export const CloudB2B2CPaidProducts = [
    {id:-999, text: "Select product", disabled: true },
    {id: CloudB2B2C_ProductIDs.ESSENTIAL.P100, text: "MProfit Wealth Essential - 100"},
    {id: CloudB2B2C_ProductIDs.ESSENTIAL.P200, text: "MProfit Wealth Essential - 200"},
    {id: CloudB2B2C_ProductIDs.ESSENTIAL.P500, text: "MProfit Wealth Essential - 500"},
    {id: CloudB2B2C_ProductIDs.ESSENTIAL.P1000, text: "MProfit Wealth Essential - 1000"},
    {id: CloudB2B2C_ProductIDs.ADVANCED.P100, text: "MProfit Wealth Advanced - 100"},
    {id: CloudB2B2C_ProductIDs.ADVANCED.P200, text: "MProfit Wealth Advanced - 200"},
    {id: CloudB2B2C_ProductIDs.ADVANCED.P500, text: "MProfit Wealth Advanced - 500"},
    {id: CloudB2B2C_ProductIDs.ADVANCED.P1000, text: "MProfit Wealth Advanced - 1000"},
    {id: CloudB2B2C_ProductIDs.PREMIUM.P100, text: "MProfit Wealth Premium - 100"},
    {id: CloudB2B2C_ProductIDs.PREMIUM.P200, text: "MProfit Wealth Premium - 200"},
    {id: CloudB2B2C_ProductIDs.PREMIUM.P500, text: "MProfit Wealth Premium - 500"},
    {id: CloudB2B2C_ProductIDs.PREMIUM.P1000, text: "MProfit Wealth Premium - 1000"},
    {id: CloudB2B2C_ProductIDs.PLATINUM.P2000, text: "MProfit Wealth Platinum - 2000"}
]

export const CloudB2B2CPaidProductsByTierForSelectProduct = {
    [B2B2C_Tier.ESSENTIAL]: [
        {id:-999, text: "Select", disabled: true },
        {id: CloudB2B2C_ProductIDs.ESSENTIAL.P100, text: "100 client portfolios", numPortfolios: 100},
        {id: CloudB2B2C_ProductIDs.ESSENTIAL.P200, text: "200 client portfolios", numPortfolios: 200},
        {id: CloudB2B2C_ProductIDs.ESSENTIAL.P500, text: "500 client portfolios", numPortfolios: 500},
        {id: CloudB2B2C_ProductIDs.ESSENTIAL.P1000, text: "1000 client portfolios", numPortfolios: 1000},
        {id: CloudB2B2C_ProductIDs.ESSENTIAL.P2000, text: "2000 client portfolios", numPortfolios: 2000},
    ],
    [B2B2C_Tier.ADVANCED]: [
        {id:-999, text: "Select", disabled: true },
        {id: CloudB2B2C_ProductIDs.ADVANCED.P100, text: "100 client portfolios", numPortfolios: 100},
        {id: CloudB2B2C_ProductIDs.ADVANCED.P200, text: "200 client portfolios", numPortfolios: 200},
        {id: CloudB2B2C_ProductIDs.ADVANCED.P500, text: "500 client portfolios", numPortfolios: 500},
        {id: CloudB2B2C_ProductIDs.ADVANCED.P1000, text: "1000 client portfolios", numPortfolios: 1000},
        {id: CloudB2B2C_ProductIDs.ADVANCED.P2000, text: "2000 client portfolios", numPortfolios: 2000},
    ],
    [B2B2C_Tier.PREMIUM]: [
        {id:-999, text: "Select", disabled: true },
        {id: CloudB2B2C_ProductIDs.PREMIUM.P100, text: "100 client portfolios", numPortfolios: 100},
        {id: CloudB2B2C_ProductIDs.PREMIUM.P200, text: "200 client portfolios", numPortfolios: 200},
        {id: CloudB2B2C_ProductIDs.PREMIUM.P500, text: "500 client portfolios", numPortfolios: 500},
        {id: CloudB2B2C_ProductIDs.PREMIUM.P1000, text: "1000 client portfolios", numPortfolios: 1000},
        {id: CloudB2B2C_ProductIDs.PREMIUM.P2000, text: "2000 client portfolios", numPortfolios: 2000},
    ],
    [B2B2C_Tier.STANDARD]: [
        {id:-999, text: "Select", disabled: true },
        {id: CloudB2B2C_ProductIDs.STANDARD.P100, text: "100 client portfolios", numPortfolios: 100},
        {id: CloudB2B2C_ProductIDs.STANDARD.P200, text: "200 client portfolios", numPortfolios: 200},
        {id: CloudB2B2C_ProductIDs.STANDARD.P500, text: "500 client portfolios", numPortfolios: 500},
        {id: CloudB2B2C_ProductIDs.STANDARD.P1000, text: "1000 client portfolios", numPortfolios: 1000},
        {id: CloudB2B2C_ProductIDs.STANDARD.P2000, text: "2000 client portfolios", numPortfolios: 2000},
    ],
    [B2B2C_Tier.PLATINUM]: [
        {id:-999, text: "Select", disabled: true },
        {id: CloudB2B2C_ProductIDs.PLATINUM.P2000, text: "2000 client portfolios", numPortfolios: 2000},
    ]
};

export const DesktopB2B2CPaidProductsByTierForSelectProduct = {
    [B2B2C_Tier.PLATINUM]: [
        {id:-999, text: "Select", disabled: true },
        {id: DesktopB2B2C_ProductIDs.PLATINUM.P2000, text: "2000 client portfolios", numPortfolios: 2000},
    ]
};

export const SystemTypes = [
    {id:-999, text: "Select Platform", disabled: true },
    {id: System_Type.CLOUD, text: "Cloud"},
    {id: System_Type.DESKTOP, text: "Windows Desktop"}
]

export function idToText(fieldName,options){
    var tempObj = options.filter(obj => obj.id === fieldName);
    return tempObj.length > 0 ? tempObj[0].text : fieldName;
}
export function textToId (value,option) {
  var tempObj = option.filter(obj => obj.text === value);
  return tempObj.length > 0 ? tempObj[0].id : value;
 } 


export const B2XDropdownOptions = [
    {id: B2X.B2B2C, text: "Wealth Professionals"},
    {id: B2X.B2C, text: "Investors" },
]

export const B2XDropdownOptionsSingular = [
    {id: B2X.B2C, text: "Investor" },
    {id: B2X.B2B2C, text: "Wealth Professional"},
]

export const B2XDropdownOptionsWithSelectForBuyPage = [
    {id:-999, text: "Select", disabled: true },
    {id: B2X.B2C, text: "I am an investor"},
    {id: B2X.B2B2C, text: "I am a wealth professional"}
]

export const ClientTypeOptions_B2C = [
    {id: Client_Type.B2C_NEW_INVESTOR, text: 'New to investing'},
    {id: Client_Type.B2C_EXPERIENCED_INVESTOR, text: 'Experienced investor'},
    {id: Client_Type.B2C_HNI, text: 'HNI'},
    {id: Client_Type.B2C_FAMILYOFFICE, text: 'Family Office'}
]

export const ClientTypeOptions_B2B2C = [
    {id: Client_Type.B2B_MFD, text: 'MF Distributor'},
    {id: Client_Type.B2B_RIA, text: 'RIA'},
    {id: Client_Type.B2B_SUBBROKER, text: 'Sub-broker / Broker'},
    {id: Client_Type.B2B_CA, text: 'Chartered Accountant'},
    {id: Client_Type.B2B_OTHER, text: 'Other'}
]

export const CustomAUMLimitDropdown_B2C = [
    {id: -999, text: "Select", disabled: true },
    {id: CustomAUMLimit_Options.CR_50, text: 'INR 50 Cr', value: 500000000},
    {id: CustomAUMLimit_Options.CR_100, text: 'INR 100 Cr', value: 1000000000},
    {id: CustomAUMLimit_Options.CONTACT_MPROFIT, text: 'Contact MProfit to subscribe to higher plans', value: CustomAUMLimit_Options.CONTACT_MPROFIT},
]

export const CustomAUMLimitDropdown_B2B2C = [
    {id: -999, text: "Select", disabled: true },
    {id: CustomAUMLimit_Options.CR_25, text: 'INR 25 Cr', value: 250000000},
    {id: CustomAUMLimit_Options.CR_50, text: 'INR 50 Cr', value: 500000000},
    {id: CustomAUMLimit_Options.CR_100, text: 'INR 100 Cr', value: 1000000000},
    {id: CustomAUMLimit_Options.CONTACT_MPROFIT, text: 'Contact MProfit to subscribe to higher plans', value: CustomAUMLimit_Options.CONTACT_MPROFIT},
]