import React from "react"
import styles from "./styles.module.css";
import Tooltip from "../../shared/Tooltip";

class ProceedButton extends React.Component {

  render() {
    const { disabled, loading, onClick, buttonText='Proceed', btnClasses=null, btnExtraClasses='', borderRadius=0, type=null, tooltip=null, selected=false} = this.props;

    return (
      <>
        <button className={(btnClasses || ["btn", "btn-block", "btn-primary", "blue_btn", btnExtraClasses].join(" ")) + (disabled || loading ? ' disabled '+styles.disabled : '') + (selected ? ' '+styles.selected : '')} style={{borderRadius}} onClick={()=>{!(disabled || loading) && onClick(disabled)}} type={type} id={tooltip ? tooltip.id : null}>
            {
                loading ?
                <i className={["fa", "fa-spinner", "fa-spin", "mr-1", "text-center", styles.spinner].join(" ")}></i>
                :
                (
                  // selected ?
                  // <i className={["fa", "fa-lg", "fa-check", "mr-2", styles.spinner].join(" ")} aria-hidden="true"></i>
                  // :
                  buttonText
                )
            }
        </button>
        {
          tooltip &&
          <Tooltip text={tooltip.text} header={tooltip.header} target={tooltip.id}></Tooltip>
        }
      </>
    )
  }
}

export default ProceedButton